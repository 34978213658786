<template>
  <table class="stylists-permissions-table table"
         :class="{'table-bordered': bordered}">
    <thead class="table-header">
      <tr class="table-row">
        <th class="table-header-cell"
            v-for="column in columns"
            :key="column.key">
          {{ column.title }}
        </th>
      </tr>
    </thead>
    <tbody class="table-body">
      <tr class="table-row table-row-hover">
        <td class="table-cell"
            v-for="column in columns"
            :key="column.key">
          <template v-if="column.type === 'boolean'">
            <base-checkbox class="centered"
                           v-model="editableStylistPermissions[column.key]"
                           @input="resetCheckingPermission(); updateUsersPermissions();"
                           v-if="column.key === 'is_admin'"/>

            <base-checkbox class="centered"
                           v-model="editableStylistPermissions.capabilities[column.key]"
                           @input="updateUsersPermissions"
                           v-else-if="column.key !== 'is_checking_required' || !editableStylistPermissions.is_admin"/>
          </template>

          <base-text-box class="textbox"
                         type="number"
                         v-model="editableStylistPermissions.capabilities[column.key]"
                         @input="updateUsersPermissions"
                         v-if="column.type === 'number'"/>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  export default {
    props: {
      stylistPermissions: {
        required: true,
        type: Object
      },

      bordered: {
        required: false,
        type: Boolean,
        default: false
      }
    },

    model: {
      prop: 'stylistPermissions',
      event: 'update'
    },

    data() {
      return {
        editableStylistPermissions: [],
        columns: this.$t('stylistPermissions')
      }
    },

    methods: {
      resetCheckingPermission() {
        this.editableStylistPermissions.capabilities.is_checking_required = false
      },

      updateUsersPermissions(event) {
        this.$emit('update', _.cloneDeep(this.editableStylistPermissions))
      },
    },

    watch: {
      stylistPermissions: {
        handler(value) {
          this.editableStylistPermissions = _.cloneDeep(value)
        },
        deep: true,
        immediate: true
      },
    }
  }
</script>

<style lang="scss" scoped>
  .textbox {
    margin: 0;
  }
</style>