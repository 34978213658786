<template>
  <div class="stylist-profile"
       v-if="stylist">
    <div class="content">
      <div class="section-left">
        <div class="panel panel-main-information">
          <div class="avatar">
            <base-avatar :user="stylist"
                         size="stretch"/>
          </div>

          <stylists-profile-info-block :label="$t('admin.stylists.profile.nickNameLabel')"
                                       :value="stylist.nickName">
          </stylists-profile-info-block>
          <stylists-profile-info-block :label="$t('admin.stylists.profile.fullNameLabel')"
                                       :value="stylist.fullName">
          </stylists-profile-info-block>
          <stylists-profile-info-block :label="$t('admin.stylists.profile.emailLabel')"
                                       :value="stylist.email"
                                       linkType="email">
          </stylists-profile-info-block>
        </div>
      </div>

      <div class="section-right">
        <div class="panel panel-tools">
          <router-link :to="{ name: 'admin.stylists' }"
                       class="back-to-list">
            {{ $t('admin.stylists.profile.backToList') }}
          </router-link>
        </div>

        <div class="panel">
          <stylists-permissions-table class="permissions-table"
                                              v-model="stylistPermissions"/>

          <base-button class="full-width"
                       :loading="loading"
                       @click="updateStylistPermissions">
            {{ $t('admin.stylists.profile.save') }}
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import profileService from '@/services/queries/profileQueries'
  import StylistsProfileInfoBlock from './StylistsProfileInfoBlock.vue'
  import StylistsPermissionsTable from '../StylistsPermissionsTable.vue'

  export default {
    components: {
      StylistsProfileInfoBlock,
      StylistsPermissionsTable,
    },

    data() {
      return {
        stylist: null,
        stylistPermissions: null,
        loading: false,
      }
    },

    metaInfo() {
      return {
        title: this.$t('admin.stylists.profile.pageTitle')
      }
    },

    created() {
      this.getStylist()
    },

    methods: {
      getStylist() {
        profileService.find(this.$route.params.id).then(data => {
          this.stylist = data
          this.stylistPermissions = _.pick(data, ['user_id', 'is_admin', 'capabilities'])
        })
      },

      resetCheckingPermission() {
        this.stylistPermissions.capabilities.is_checking_required = false
      },

      updateStylistPermissions() {
        this.loading = true

        profileService.update(this.stylist.id, this.stylistPermissions).then(() => {
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
 .stylist-profile {
    display: flex;
    justify-content: center;
    min-height: calc(100% - 70px);
    background-color: #f8f8f8;

    .content {
      display: flex;
      width: 960px;
      margin: 40px 0;

      .panel {
        margin-bottom: 20px;
        padding: 20px;

        &:last-child {
          margin: 0;
        }
      }

      .section-left {
        flex-basis: 40%;
        margin-right: 20px;

        .panel-main-information {
          display: flex;
          flex-direction: column;
          padding-top: 40px;

          .avatar {
            align-self: center;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 250px;
            width: 250px;
            margin-bottom: 40px;
          }
        }
      }

      .section-right {
        display: flex;
        flex-direction: column;
        flex-basis: 60%;

        .panel-tools {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;
          padding: 20px;

          .back-to-list {
            font-size: 16px;
            color: #797979;
            cursor: pointer;

            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }

    .permissions-table {
      margin-bottom: 20px;
    }
  }
</style>
