<template>
  <div class="stylist-profile-info-block"
      :class="{ 'single-row': labelLeft }">
    <div v-if="label"
         class="label">{{ label }}:</div>

    <div v-if="!linkType"
         class="value text"
         :class="{ 'multiline': textMultiline }">
      <slot>{{ value || placeholder }}</slot>
    </div>

    <a v-else
       class="value link"
       :href="linkHrefValue">{{ value }}</a>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        required: false
      },

      label: {
        required: false,
        type: String
      },

      placeholder: {
        required: false,
        type: String
      },

      textMultiline: {
        required: false,
        type: Boolean,
        default: false
      },

      linkType: {
        required: false,
        type: String
      },

      labelLeft: {
        required: false,
        type: Boolean,
        default: true
      }
    },

    computed: {
      linkHrefValue() {
        let specialLinkTypes = {
          email: 'mailto:',
          phoneNumber: 'tel:',
          website: '//'
        }

        // for custom link types (for example, 'skype:')
        if (!specialLinkTypes[this.linkType]) {
          return this.linkType + this.value
        }

        return specialLinkTypes[this.linkType] + this.value
      }
    }
  }
</script>

<style lang="scss" scoped>
  .stylist-profile-info-block {
    margin-bottom: 10px;

    &:last-child {
      margin: 0;
    }

    .label {
      margin-bottom: 5px;
      font-weight: 600;
      color: #797979;
    }

    .value {
      font-size: 16px;
      line-height: 1.5em;
    }

    .text {
      &.multiline {
        white-space: pre-wrap;
      }
    }

    &.single-row {
      display: flex;
      align-items: center;

      .label {
        margin: 0 5px 0 0;
      }
    }
  }
</style>